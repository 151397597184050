import styled from '@emotion/styled'

import { mediaMax, sectionTitle, sectionSubtitle } from '@/styles/mixins'

import { ButtonWrapper as Button } from '@/components/Button/style'
import { Wrapper as DropDownWrapper } from '@/components/Forms/Inputs/Select/style'
import Link from '@/components/LocalizedGatsbyLink'
import WysiwygBase from '@/components/Wysiwyg'
import theme from '@/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  a{
    width: auto;
  }


  ${mediaMax.xs} {
    ${DropDownWrapper} {
      margin-top: 0 !important;
      margin-bottom: 3rem !important;
    }
  }
`

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.lightGreen};
  ${sectionSubtitle()}
  letter-spacing: .1rem;
  margin-bottom: 3rem;

  ${mediaMax.xs} {
    letter-spacing: .2rem;
  }
`

export const Title = styled.h3`
  ${sectionTitle()}
  width: 100%;
  font-size: 4.5rem;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: ${({ spaceY }) => spaceY ? '2rem' : '0'};

  ${mediaMax.xs} {
    font-size: 5rem;
    margin-bottom: 0;
  }
`

export const Wrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction : row;
  align-items: center;
  gap: 1.5rem;

  ${mediaMax.xs}{
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;

    ${Button}{
      width: 100%;
      padding: 1.7rem 3.5rem;
    }
  }
`

export const VideoCtaLabel = styled.span`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.greyText};
  line-height: 1rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  margin-left: 0.5rem;
  transition: opacity .2s ease-out;
`

export const VideoCtaIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  margin-left: 2.5rem;
  transition: opacity .1s ease-out;

  ${mediaMax.xs}{
    margin-left: 0;
  }
`

export const VideoCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .75rem;
  cursor: pointer;

  ${mediaMax.xs}{
    margin-top: 2rem;
  }

  &:hover {
    ${VideoCtaLabel}, ${VideoCtaIcon}{
      opacity: .6;
    }
  }
`

export const Wysiwyg = styled(WysiwygBase)`
  font-size: ${({ theme }) => theme.fontSizes.paragraph.lg};
  font-family: ${p => p.theme.fonts.sansSerif};
  color: ${p => p.theme.colors.brown};
  letter-spacing: .1rem;
  line-height: 1.6em;
  margin-bottom: 1rem;
  width: 80% !important;

  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.xs};
  }

  ${mediaMax.xs} {
    width: 100% !important;
  }
`

export const Action = styled.div`
  margin-top: 4rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${Button}{
    padding: 2rem 6rem;
  }
  ${mediaMax.xs} {
    ${Button}{
      width: 100%;
      padding: 1.7rem 3.5rem;
    }
  }
`

export const Recommendation = styled.div`
  font-family: ${p => p.theme.fonts.serif};
  font-size: 1.3rem;
  margin: .75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RecommendationLink = styled.a`
  color: ${p => p.theme.colors.brown};
  font-family: ${p => p.theme.fonts.serif};
  font-size: 1.3rem;
  margin: .75rem 0;
  text-decoration: underline;
`

export const RecommendationSpecial = styled.div`
  margin-left: .8rem;
  font-family: ${p => p.theme.fonts.sansSerif};
  transform: translateY(-0.1rem);
  display: inline-flex;
`

export const RecommendationSpecialLogo = styled.img`
  height: 1.2rem;
  margin-right: .8rem;
`

export const RecommendationSpecialLabel = styled.span`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #6C8255;
`

export const Actions = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  margin-top: 2rem;
  flex-direction: column;

  ${Button} {
    width: fit-content;

    span {
      font-size: 1.2rem;
    }
  }

  &.many{
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    padding-right: .75rem;
    justify-content: flex-start;
    align-items: normal;

    ${Button}{
      width: fit-content;
    }

    span{
      font-size: 1.1rem;
    }
  }

  ${mediaMax.lg}{
    &.many{
      ${Button}{
        width: fit-content;
        gap: .75rem;
        padding: 2rem 2.4rem;
      }
      span{
      font-size: 1.2rem;
      }
    }
  }
  ${mediaMax.xs} {
    justify-content:flex-start;
    gap: 1rem;
    width: 100%;
    &.many {
      flex-direction: column;
      gap: 1.5rem;
      padding-right: 0;

      ${Button}{
        width: 100%;
        padding: 2rem 2.4rem;
      }
    }
  }
`
